import { Caption2SemiBold } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

// FooterLinks keys must match the keys in en.json to display the link label
import { FOOTER_LINKS, loggedInUserHelpCenterUrl } from '~/constants'
import { currentUserVar } from '~/graphql/reactive-vars'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

interface FooterProps {
  className?: string
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const t = useTranslationForNamespace('common')
  const year = new Date().getFullYear()

  const currentUser = currentUserVar()

  const linksClone: Record<string, string> = { ...FOOTER_LINKS }

  if (currentUser) {
    linksClone.help = loggedInUserHelpCenterUrl
  }

  return (
    <FooterContainer className={className}>
      <Copyright>© {year} LUMOS LABS, INC.</Copyright>
      <Links>
        {Object.entries(linksClone).map(([label, link]) => (
          <LinkContainer key={label}>
            <a href={link}>
              <LinkText>{t(`footer.${label}`)}</LinkText>
            </a>
          </LinkContainer>
        ))}
      </Links>
    </FooterContainer>
  )
}

const Copyright = styled(Caption2SemiBold)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    padding: 15px;
  }
  color: ${({ theme }) => theme.colors.neutral[200]};
  padding-top: 5px;
  text-transform: uppercase;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 40px;
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
`

const LinkContainer = styled.span`
  padding: 0 16px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    text-align: center;
  }
`

const Links = styled.div`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`

const LinkText = styled(Caption2SemiBold)`
  color: ${({ theme }) => theme.colors.neutral[200]};
`

export default Footer
